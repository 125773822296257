import styled from '@emotion/styled';
import { ClickAwayListener, Grow, MenuList, Paper, Popper } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MessageIcon from '@material-ui/icons/Message';
import SendIcon from '@material-ui/icons/Send';
import { format } from 'date-fns';
import { css } from 'emotion';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { useSubStore } from 'src/modules/Common/context/sub-store-context';
import { usePaginationArguments } from 'src/modules/Common/custom-hooks/usePaginationArguments';

import {
    useGetSmsTemplatesQuery,
    useMyOrdersQuery,
    useOnServiceOrderChangeForStoreSubscription,
    useSendBulkCustomerSmsMutation,
} from '../../../generated/graphql';
import LoaderCircular from '../../Common/Common/LoaderCircular';
import { useStore } from '../../Common/context/store-context';
import { useDialog3 } from '../../Common/custom-hooks/useDialog3';
import { useInterval } from '../../Common/custom-hooks/useInterval';
import ErrorMessage from '../../Common/ErrorMessage/ErrorMessage';
import AppButton from '../../Common/Form/AppButton';
import { formatDate } from '../../Common/helpers/dates';
import { NoResult } from '../../Layout/QuickSearch/components/Dialog/Panels/QuickSearch';
import ServiceOrderDialog from '../../ServiceOrder/components/Dialogs/ServiceOrderDialog';
import { orderOriginText, orderStatusText } from '../../ServiceOrder/utils/serviceOrderFields';

const tableStyles = css`
    border: 2px solid #dfdfdf;
    border-radius: 5px;
    min-height: 100px;
`;

const Wrapper = styled.div`
    position: relative;
`;

const LoadWrapper = styled.div`
    position: relative;
    padding-top: 150px;
`;

const columns = [
    {
        name: 'Ordernummer',
        selector: 'orderNumber',
        sortable: true,
        grow: 1,
    },
    {
        name: 'Cykeltyp',
        selector: 'bikeType',
        cell: (row) => row.bikeType || '-',
        sortable: true,
    },
    {
        name: 'Kundens namn',
        selector: 'customer.name',
        cell: (row) => row.customer?.companyName || row.customer?.name || '-',
        sortable: false,
    },
    {
        name: 'Beskrivning',
        selector: 'description',
        sortable: true,
    },
    {
        name: 'Mekaniker',
        selector: 'serviceByName',
        sortable: true,
    },
    {
        name: 'Status',
        selector: 'status',
        cell: (row) => orderStatusText(row.status),
        sortable: true,
    },
    {
        name: 'Källa',
        selector: 'origin',
        cell: (row) => orderOriginText(row.origin),
        width: '80px',
        sortable: true,
    },
    {
        name: 'Planerat datum',
        selector: 'serviceDate',
        cell: (row) => format(new Date(row.serviceDate), 'yyyy-MM-dd'),
        width: '140px',
        sortable: true,
    },
    // {
    //     button: true,
    //     cell: (row) => (
    //         <AppTableButton onClick={() => open(row)} icon={faEdit}/>
    //     ),
    // },
];

const customStyles = {
    rows: {
        style: {
            cursor: 'pointer',
        },
    },
};

export default function OrderTable(props) {
    const { category, showBulk = false } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState();
    const [checked, setChecked] = useState([]);

    const [storeId] = useStore();
    const { selectedSubStoreId } = useSubStore();
    const { createDialog } = useDialog3();
    const { enqueueSnackbar } = useSnackbar();
    const [pagination, setPagination] = usePaginationArguments();

    const { data, loading, refetch, error } = useMyOrdersQuery({
        fetchPolicy: 'no-cache', // We dont want to trigger anything else to refetch
        onCompleted: (data) => {
            setPagination({ ...pagination, totalCount: data.myOrders.total });
        },
        variables: { storeId, subStoreId: selectedSubStoreId, category },
        // pollInterval: 6 * 1000, // removed because it does not unmount when in tabs
    });

    // @todo All functions releated to the bulk action should have been put it its own hook.ö
    // @todo The number of messages that could fail is not none or all and should be clarified to the user.
    //  Ie. Failed to send message to the following orders/customers/phone numbers
    const [onSendBulkCustomerSms] = useSendBulkCustomerSmsMutation({
        onCompleted: () => {
            enqueueSnackbar('Sms skickat', { variant: 'success' });
        },
        onError: () => {
            enqueueSnackbar('Något gick fel', { variant: 'error' });
        },
    })

    const { data: smsTemplates, loading: templatesLoading } = useGetSmsTemplatesQuery()


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSend = async () => {
        const input = checked.map((id) => {
            const order = data?.myOrders?.items.find((order) => order.id === id);

            return ({
                serviceOrderId: id,
                templateKey: selectedTemplate.template,
                date: formatDate(order?.serviceDate),
                externalKey: order?.externalKey,
            });
        });
        
        await onSendBulkCustomerSms({
            variables: {
                input,
            },
        });
    }

    useInterval(refetch, 1000 * 60); // Needed because pollInterval does not work

    useOnServiceOrderChangeForStoreSubscription({
        variables: { storeId },
        onSubscriptionData: () => refetch(), // we don't care about the result. Just refetch stats.
    })

    if (error) {
        return <ErrorMessage error={error} />;
    }

    if (!data || loading || templatesLoading || !smsTemplates) {
        return <LoadWrapper><LoaderCircular size={50} /></LoadWrapper>;
    }

    const templates = smsTemplates?.templates ?? [];

    const showServiceOrder = (clickedRow) => {
        createDialog((ref) => (
            <ServiceOrderDialog
                serviceOrderId={clickedRow.id}
                dialogRef={ref}
            />
        ));
    };

    const reFetchMyOrders = async (args) => {
        setPagination({ ...pagination, ...args });
        await refetch({ ...pagination, ...args });
    };

    return (
        <Wrapper>
            <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
                marginBottom: '10px',
            }}>
                {showBulk && <>
                    <AppButton
                        startIcon={<MessageIcon />}
                        onClick={handleClick}
                        style={{ paddingRight: '7px' }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                            {selectedTemplate?.label || 'Välj mall'}
                            <ArrowDropDownIcon />
                        </div>
                    </AppButton>
                    <Popper anchorEl={anchorEl} open={!!anchorEl}>
                        <Grow
                            style={{ transformOrigin: 'center bottom' }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <Paper style={{ marginTop: '5px' }}>
                                    <MenuList autoFocusItem={!!open} id='menu-list-grow'>
                                        {templates?.map(({ template, label }, index) =>
                                            <MenuItem key={index} onClick={() => {
                                                setSelectedTemplate({ template, label });
                                                handleClose();
                                            }}>
                                                {label}
                                            </MenuItem>,
                                        )}
                                    </MenuList>
                                </Paper>
                            </ClickAwayListener>
                        </Grow>
                    </Popper>
                    <AppButton
                        disabled={checked.length === 0 || !selectedTemplate}
                        startIcon={<SendIcon />}
                        onClick={() => handleSend()}
                    >
                        Skicka sms
                    </AppButton>
                </>}
            </div>
            <div className={tableStyles}>
                <DataTable
                    noHeader
                    pagination
                    columns={columns}
                    selectableRows={showBulk}
                    onSelectedRowsChange={({ selectedRows }) => {
                        setChecked(selectedRows.map((row) => row.id));
                    }}
                    customStyles={customStyles}
                    data={data?.myOrders?.items ?? []}
                    defaultSortField={'serviceDate'}
                    onRowClicked={showServiceOrder}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Poster per sida',
                        rangeSeparatorText: 'av',
                        selectAllRowsItemText: 'Alla',
                    }}
                    paginationServer={true}
                    paginationPerPage={20}
                    paginationRowsPerPageOptions={[20, 50, 100]}
                    paginationTotalRows={data.myOrders.total}
                    onChangePage={(page) => {
                        reFetchMyOrders({ page });
                    }}
                    onChangeRowsPerPage={(pageSize) => {
                        reFetchMyOrders({ pageSize });
                    }}
                    onSort={(col, sortDirection) => {
                        reFetchMyOrders({
                            orderBy: col.selector,
                            orderDir: sortDirection.toUpperCase(),
                        });
                    }}
                    sortServer={true}
                    striped
                    noDataComponent={
                        <NoResult>Det finns ingen data att visa</NoResult>
                    }
                />
            </div>
        </Wrapper>
    );
}
